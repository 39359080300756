<template>
  <Layout :navigation="navigationObj">
    <router-view />
  </Layout>
</template>

<script>
import Layout from "../components/Layout";

export default {
  name: "Tracking",
  data() {
    return {
      navigationObj: {
        items: [
          {
            title: "Organizations",
            name: "tracking-organizations",
            routeName: "tracking-organizations",
          },
          {
            title: "Facilities",
            name: "facilities",
            routeName: "facilities",
            isSection: true,
          },
          {
            title: "Create new Facility",
            name: "create-facility",
            routeName: "create-facility",
          },
          {
            title: "Tracking reports",
            name: "tracking-reports",
            routeName: "tracking-reports",
          },
          {
            title: "Plastic credits",
            name: "plastic-credits",
            routeName: "plastic-credits",
          },
          {
            title: "Category management",
            name: "category-manager",
            routeName: "category-manager",
          },
          {
            title: "Delterra Cat mgmt",
            name: "category-manager-delterra",
            routeName: "category-manager-delterra",
          },
        ],
      },
    };
  },
  components: {
    Layout,
  },
};
</script>

<style scoped>
</style>
